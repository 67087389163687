*{
  padding: 0;
  margin: 0;
}

body{
  background: radial-gradient(turquoise 15%,green);
  background-size: 100% 150%;
  background-position: 50% 50%; 
  min-height: 100vh;
}

.App{
  display: grid;
  justify-content: center;
  text-align: center;
  gap: 10px;
  padding: 30px;
  font-family: 'Goldman', sans-serif;
  color: white;
}

#Header{
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 10px;
}

#TileContainer{
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  width: fit-content;
  background: #FFFFFF80;
  padding: 30px;
  border-radius: 30px;
}

#Tile{
  width: 100px;
  height: 100px;
  background: radial-gradient(darkslategray,black);
  border-radius: 10px;
  filter: drop-shadow(0 2px 3px #00000050);
  
}

#Bomb{
  background: radial-gradient(darkslategray,black);
  width: 100px;
  height: 100px;
  border-radius: 10px;
  filter: drop-shadow(0 2px 3px #00000050);
}

#BombSlider{
  width: 50%;
  height: 10px;
  background: white;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 25px;
  justify-self: center;
  margin: 10px;
  cursor: pointer;
  accent-color: darkslategray;
}


#Tile:hover, #Bomb:hover{
  cursor: pointer;
  filter: brightness(130%);
}

button{
  width: fit-content;
  justify-self: center;
  padding: 20px 30px;
  background: white;
  border: none;
  border-radius: 10px;
  font-family: 'Goldman', sans-serif;
}

button:hover{
  cursor: pointer;
  background: black;
  color: white;
  
}

#Popup{
  display: grid;
  position: absolute;
  z-index: 2;
  backdrop-filter: blur(20px);
  width: stretch;
  height: stretch;
  top: 0;
}

#PopupContainer{
  display: grid;
  gap: 20px;
  justify-self: center;
  align-self: center;
  padding: 5%;
  background: #FFFFFF80;
  border: 1px solid #FFFFFF80;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  filter: drop-shadow(0 10px 10px black);
}

@media only screen and (max-width:500px) {
  body{
    scale: 70%;
  }

  .App{
    height: fit-content;
  }
}